import React from 'react';
import '../styles/ContactInfo.css'; // Assuming you will create this file for styling

function ContactInfo() {
    return (
        <div className="contact-info-container">
            <h3 className="contact-heading">联系我们</h3>
            <p className="contact-description">
                如有任何问题或需要进一步帮助？我们随时为你提供支持！<br /> 请通过以下任意邮箱联系我们，我们会在24小时内及时回复。
            </p>
            <ul className="contact-details">
                <li>
                    <i className="fas fa-envelope"></i> 邮箱: <a href="mailto:support@doexambee.com">support@doexambee.com</a>
                </li>
                <li>
                    <i className="fas fa-comments"></i> 微信 id: <span>examsbee</span>
                </li>
            </ul>
        </div>
    );
}

export default ContactInfo;

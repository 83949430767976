import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/PaymentPage.css'; // Ensure you have this CSS file for styling
import LoadingIndicator from '../components/Loading';

function PaymentPage() {
    const [quantity, setQuantity] = useState(1);
    const [paymentMethod, setPaymentMethod] = useState(''); // State to track selected payment method
    const unitPrice = 499;
    const [isLoading, setIsLoading] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const [discount, setDiscount] = useState(0); // State to track the discount amount
    const [error, setError] = useState(null); // State to handle coupon validation errors
    const [userEmail, setUserEmail] = useState('');
    const [username, setUsername] = useState('');

    const location = useLocation();
    const { examName } = location.state || {};

    const totalPrice = ((quantity * unitPrice) - discount).toFixed(2);

    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user && user.email) {
            setUserEmail(user.email);
            setUsername(user.userName);
        }
    }, []);

    const handlePaymentMethodChange = (method) => {
        setPaymentMethod(method);
    };

    const handleApplyCoupon = async() => {
        // Replace this with real validation logic
        if (!couponCode) {
            setDiscount(0);
            setError('请输入折扣码');
            return;
        }
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/payment/checkDiscountCoupon`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include', 
            body: JSON.stringify({
                couponCode: couponCode,
            }),
        });

        if (response.status === 204 || response.status === 404) {
            // No content or not found
            setError('折扣码无效');
            setDiscount(0);
            return;
        }
        if (!response.ok) {
            setError('无法验证折扣码');
            setDiscount(0); // Reset discount if the coupon is invalid
            return;
        }

        const discountCouponData = await response.json();
        console.log("discountCouponData");
        console.log(discountCouponData);
        if (discountCouponData && discountCouponData.couponStatus === "active") {
            setDiscount(discountCouponData.couponValue);
            setError(null);
        } else {
            setError('折扣码无效');
            setDiscount(0);
        }
    };

    const handlePurchase = async () => {
        setIsLoading(true); 
        const user = JSON.parse(sessionStorage.getItem('user'));
        const email = user.email;
        const paymentRequest = {
            email: email,
            examType: examName,
            paymentMethod: paymentMethod,
            couponCode: couponCode,
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/payment/createPayment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(paymentRequest),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.text();
            
            // Assuming the response contains redirection URL from Alipay
            const parser = new DOMParser();
            const doc = parser.parseFromString(data, 'text/html');
            const form = doc.querySelector('form');
            document.body.appendChild(form);
            form.submit();
    
        } catch (error) {
            console.error('Error processing payment:', error);
            alert('There was an error processing the payment. Please try again.');
        }
    };


    return (
        isLoading ? <LoadingIndicator /> : 
        (
        <div className="order-review-container">
            <h2>订单信息</h2>

            {userEmail && (
                <div className="user-info">
                    {/* <p className="user-name">{username}</p> */}
                    <p className="user-email">用户邮箱: {userEmail}</p>
                </div>
            )}

            <table className="order-table">
                <thead>
                    <tr>
                        <th>商品名称</th>
                        <th>数量</th>
                        <th>价格</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="product-info">
                            <div className="product-details">
                                <p>{examName} 模考套题</p>
                            </div>
                        </td>
                        <td className="quantity">{quantity}</td>
                        <td className="total-price">¥ {totalPrice}</td>
                    </tr>
                </tbody>
            </table>

            <div className="order-summary">
                <p><strong>总计: ¥ {totalPrice}</strong></p>
            </div>

            <div className="coupon-container">
                <input
                    type="text"
                    className="coupon-input"
                    placeholder="可在此输入折扣码"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                />
                <button className="apply-coupon-button" onClick={handleApplyCoupon}>确认</button>
            </div>
            {error && <p className="error-message">{error}</p>}

            <div className="payment-options">
                <div className="payment-option">
                    <label>
                        <input
                            type="radio"
                            value="alipay"
                            checked={paymentMethod === 'alipay'}
                            onChange={() => handlePaymentMethodChange('alipay')}
                        />
                        <span className="payment-label">
                            <img src={require('../assets/alipay.png')} alt="Alipay" className="payment-icon" />
                            支付宝
                        </span>
                    </label>
                </div>
                {/* <div className="payment-note">如果支付时显示支付到达上限请联系客服微信examsbee, 我们正在紧急修复此问题...</div> */}
            </div>

            <button className="proceed-button" disabled={!paymentMethod} onClick={() => handlePurchase()}>继续支付</button>
        </div>
        )
    );
}

export default PaymentPage;

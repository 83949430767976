import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Latex from 'react-latex-next';
import ExamHeader from '../components/ExamHeader';
import 'katex/dist/katex.min.css';
import '../styles/ExamReview.css';
import { calculateScoreForMath1Part, calculateScoreForMath2Part, calculateScoreForPhysicsPart, calculateScoreForPAT } from '../utils/scoreCalculator';

function ExamReview() {
    const location = useLocation();
    const { questions, selectedAnswers, topic, subTopic} = location.state || { questions: [], selectedAnswers: [], topic: '', subtopic: '' };
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const currentQuestion = questions[currentQuestionIndex];

    const [showReportModal, setShowReportModal] = useState(false);
    const [reportText, setReportText] = useState('');

    const handleReportSubmit =  () => {

        fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/question/report`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                questionId: currentQuestion.questionData.id,
                reportText,
            }),
        });
        setShowReportModal(false);;
    };

    // Calculate the score
    const accuracy = questions.reduce((acc, question, index) => {
        if (selectedAnswers[index] === question.questionData.correctSelection) {
            return acc + 1;
        }
        return acc;
    }, 0);

    const isUrl = (text) => {
    // Check if the text is a valid URL (simple check)
        return text.startsWith('http://') || text.startsWith('https://');
    };

    let score = 0;
    if (topic === 'Mathematics1') {
        score = calculateScoreForMath1Part(accuracy);
    } else if (topic === 'Mathematics2') {
        score = calculateScoreForMath2Part(accuracy);
    } else if (topic === 'Physics') {
        score = calculateScoreForPhysicsPart(accuracy);
    } else if (topic === 'PAT') {
        score = calculateScoreForPAT(accuracy);
    } else if (topic === "Chemistry") {
        score = calculateScoreForPhysicsPart(accuracy);
    } else if (topic === "Biology") {
        score = calculateScoreForPhysicsPart(accuracy);
    }

    // Preload images function
    const preloadImages = (questions) => {
        questions.forEach((question) => {
            if (question.questionData.questionImage) {
                const img = new Image();
                img.src = question.questionData.questionImage;
            }
            question.selections.forEach((selection) => {
                if (isUrl(selection.selectionText)) {
                    const img = new Image();
                    img.src = selection.selectionText;
                }
            });
        });
    };


    useEffect(() => {
        if (questions.length > 0) {
            preloadImages(questions); // Preload images when questions are available
        }
        // Disable scrolling on the body element when the component mounts
        document.body.style.overflow = 'hidden';
        // Re-enable scrolling on the body element when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);


    return (
        <div className="review-page">
            <ExamHeader 
            examType={"review"}
            examName={topic}
            />
        <div className="score-section">
            <div className="score-details">
                <span className="score-title">成绩详情</span>
                <div className="score-info">
                    <div className="accuracy">
                        <span className="label">答对题数：</span>
                        <span className="value">{accuracy} / {questions.length}</span>
                    </div>
                    <div className="score">
                        <span className="label">得分：</span>
                        <span className="value">{score}</span>
                    </div>
                </div>
            </div>
        </div>
            <div className="review-content">
                <aside className="question-list">
                    {questions.map((question, index) => {
                        const correctSelection = question.questionData.correctSelection;
                        const isCorrect = selectedAnswers[index] === correctSelection;
                        const itemClass = index === currentQuestionIndex 
                            ? isCorrect ? 'selected correct' : 'selected incorrect'
                            : isCorrect ? 'correct' : 'incorrect';

                        return (
                            <div
                                key={index}
                                className={`question-list-item ${itemClass}`}
                                onClick={() => setCurrentQuestionIndex(index)}
                            >
                                Question {index + 1}
                            </div>
                        );
                    })}
                </aside>
                <main className="question-details">
                    {questions.length > 0 ? (
                        <div className="question-review-container">
                            <div className="question-body section">

                                <h3 className="question-header">Question 
                                     <span>
                                        <button onClick={() => setShowReportModal(true)} className="report-error-button">Report</button>
                                    </span>
                                </h3>
                                <p>
                                    <Latex>{currentQuestion.questionData.questionFront || ''}</Latex>
                                </p>
                                <p>
                                    <Latex>{currentQuestion.questionData.questionBody || ''}</Latex>
                                </p>
                                {currentQuestion.questionData.questionImage && (
                                    <img
                                        className="question-image"
                                        src={currentQuestion.questionData.questionImage}
                                        alt={`Question ${currentQuestionIndex + 1}`}
                                    />
                                )}
                                <ul className="selections-list">
                                    {currentQuestion.selections.map((selection, index) => (
                                        <li key={index}>
                                            <label htmlFor={`selection${index}`}>
                                                <span>{String.fromCharCode(65 + index)}. </span>
                                                {isUrl(selection.selectionText) ? (
                                                // Render image if the selectionText is a URL
                                                <img src={selection.selectionText} alt="Dynamic Image" width="300px" />
                                            ) : (
                                                // Render LaTeX if the selectionText is LaTeX content
                                                <Latex>{selection.selectionText}</Latex>
                                            )}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="answer-section">
                                <div className="answer-correct-answer-container">
                                    <div className="answer section">
                                        <h3>Your Answer</h3>
                                        <p className={selectedAnswers[currentQuestionIndex] === currentQuestion.questionData.correctSelection ? 'correct' : 'incorrect'}>
                                            <Latex>{selectedAnswers[currentQuestionIndex] || "You didn't select an answer"}</Latex>
                                        </p>
                                    </div>
                                    <div className="correct-answer section">
                                        <h3>Correct Answer</h3>
                                        <p>
                                            <Latex>{currentQuestion.questionData.correctSelection || ''}</Latex>
                                        </p>
                                    </div>
                                </div>
                                <div className="explanation section">
                                    <h3>Explanation</h3>
                                    <p>
                                        <Latex>{currentQuestion.questionData.questionExplanation || ''}</Latex>
                                    </p>
                                </div>
                            </div>

                            {showReportModal && (
                                <div className="report-modal">
                                    <div className="report-modal-content">
                                        {/* Close button at the top-right */}
                                        <button className="close-button" onClick={() => {
                                            setShowReportModal(false)
                                            setReportText('')
                                        }}>×</button>
                                        <h3>Report an Error</h3>
                                        <textarea
                                            placeholder="Describe the issue..."
                                            value={reportText}
                                            onChange={(e) => setReportText(e.target.value)}
                                        />
                                        <div className="button-container">
                                            <button onClick={handleReportSubmit}>Submit Report</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <p>No questions available</p>
                    )}
                </main>
            </div>
        </div>
    );
}

export default ExamReview;

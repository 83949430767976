import React, { useState, useEffect } from 'react';
import Latex from 'react-latex-next';
import Modal from 'react-modal';
import { useNavigate, useLocation } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import '../styles/ExamPage.css';
import Draggable from 'react-draggable'; 
import LoadingIndicator from '../components/Loading';
import ExamHeader from '../components/ExamHeader';
import { calculateScoreForMath1Part, calculateScoreForMath2Part, calculateScoreForPhysicsPart, calculateScoreForPAT } from '../utils/scoreCalculator';
import ExamTypeModal from '../components/ExamTypeModal';
import Calculator from '../components/Calculator';

Modal.setAppElement('#root');

function ExamPage() {
    const location = useLocation();
    const { topic, subTopic, examType, questionInfo } = location.state || {};

    const [questions, setQuestions] = useState(() => {
        const savedQuestions = sessionStorage.getItem('questions');
        return savedQuestions !== null ? JSON.parse(savedQuestions) : [];
    });


    console.log(questions);
    const [error, setError] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
        const savedIndex = sessionStorage.getItem('currentQuestionIndex');
        return savedIndex !== null ? Number(savedIndex) : 0;
    });
    const [selectedAnswers, setSelectedAnswers] = useState(() => {
        const savedAnswers = sessionStorage.getItem('selectedAnswers');
        return savedAnswers !== null ? JSON.parse(savedAnswers) : [];
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [questionStatuses, setQuestionStatuses] = useState(() => {
        const savedStatuses = sessionStorage.getItem('questionStatuses');
        return savedStatuses !== null ? JSON.parse(savedStatuses) : new Array(questions.length).fill('Unseen');
    });
    const [flaggedQuestions, setFlaggedQuestions] = useState(() => {
        const savedFlaggedQuestions = sessionStorage.getItem('flaggedQuestions');
        return savedFlaggedQuestions !== null ? JSON.parse(savedFlaggedQuestions) : [];
    });
    const [timeRemaining, setTimeRemaining] = useState(() => {
        const savedTime = sessionStorage.getItem('timeRemaining');
        return savedTime !== null ? Number(savedTime) : topic === "PAT"? 7200: 2400;
    });
    const [timerEnded, setTimerEnded] = useState(false);
    const [loading, setLoading] = useState(false); 
    const navigate = useNavigate();
    const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);
    const toggleCalculator = () => {
        setIsCalculatorOpen(prevState => !prevState);
    };

    const isUrl = (text) => {
        // Check if the text is a valid URL (simple check)
        return text.startsWith('http://') || text.startsWith('https://');
      };

      const preloadImages = (questions) => {
        questions.forEach((question) => {
            if (question.questionData.questionImage) {
                const img = new Image();
                img.src = question.questionData.questionImage;
            }
            question.selections.forEach((selection) => {
                if (isUrl(selection.selectionText)) {
                    const img = new Image();
                    img.src = selection.selectionText;
                }
            });
        });
    };
    

    useEffect(() => {
        sessionStorage.setItem('selectedAnswers', JSON.stringify(selectedAnswers));
    }, [selectedAnswers]);

    function extractMockExamNumber(subTopic) {
        try {
            return subTopic.replace(/[^0-9]/g, '');
        } catch (error) {
            throw new Error(`Invalid subtopic format: ${subTopic}`);
        }
    }

    let examClass;
    if (topic === "Mathematics1") {
        examClass = extractMockExamNumber(subTopic);
    } else if (topic === "Mathematics2") {
        const mockNum = parseInt(extractMockExamNumber(subTopic), 10) + 12;
        examClass = String(mockNum);
    } else if (topic === "Physics") {
        const mockNum = parseInt(extractMockExamNumber(subTopic), 10) + 18;
        examClass = String(mockNum);
    } else if (topic === "PAT") {
        examClass = parseInt(extractMockExamNumber(subTopic), 10) + 35;
    } else if (topic === "Chemistry") {
        examClass = parseInt(extractMockExamNumber(subTopic), 10) + 51;
    } else if (topic === "Biology") {
        examClass = parseInt(extractMockExamNumber(subTopic), 10) + 58;
    }

    const addExamHistory = async () => {
        try {
            const user = JSON.parse(sessionStorage.getItem('user'));
            const userEmail = user.email;

            // Create the wrongQuestionAnswers array
            const wrongQuestionAnswers = questions.reduce((acc, question, index) => {
                if (selectedAnswers[index] !== question.questionData.correctSelection) {
                    acc.push({
                        wrongQuestionId: question.questionData.id,
                        wrongAnswerId: selectedAnswers[index] || 'No Answer Selected'
                    });
                }
                return acc;
            }, []);

            // Calculate the score
            const accuracy = questions.reduce((acc, question, index) => {
                if (selectedAnswers[index] === question.questionData.correctSelection) {
                    return acc + 1;
                }
                return acc;
            }, 0);
        
            let score = 0;
            if (topic === 'Mathematics1') {
                score = calculateScoreForMath1Part(accuracy);
            } else if (topic === 'Mathematics2') {
                score = calculateScoreForMath2Part(accuracy);
            } else if (topic === 'Physics') {
                score = calculateScoreForPhysicsPart(accuracy);
            } else if (topic === 'PAT') {
                score = calculateScoreForPAT(accuracy);
            } else if (topic === "Chemistry") {
                score = calculateScoreForPhysicsPart(accuracy);
            } else if (topic === "Biology") {
                score = calculateScoreForPhysicsPart(accuracy);
            }

            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/history/addExamHistory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', 
                body: JSON.stringify({
                    examClass: examClass, // Use the topic as the exam name
                    userEmail: userEmail, // Retrieve or set the correct user email
                    examResult: score, // Send the calculated score
                    examTime: new Date().toISOString(), // Send the current time
                    wrongQuestionAnswers // Include the wrong answers
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to add exam history');
            }

            // Handle the response if necessary
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false); // Set loading to false after the request is finished
        }
    };


    const handleNext = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            updateQuestionStatus(currentQuestionIndex + 1);
            sessionStorage.setItem('currentQuestionIndex', currentQuestionIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            updateQuestionStatus(currentQuestionIndex - 1);
            sessionStorage.setItem('currentQuestionIndex', currentQuestionIndex - 1);
        }
    };

    const updateQuestionStatus = (index) => {
        const updatedStatuses = [...questionStatuses];
        if (updatedStatuses[index] !== 'Complete') {
            updatedStatuses[index] = 'Incomplete';
        }
        setQuestionStatuses(updatedStatuses);
        sessionStorage.setItem('questionStatuses', JSON.stringify(updatedStatuses));
    };

    const handleSelectionChange = (index, value) => {
        const updatedAnswers = [...selectedAnswers];
        updatedAnswers[currentQuestionIndex] = value;
        setSelectedAnswers(updatedAnswers);
        sessionStorage.setItem('selectedAnswers', JSON.stringify(updatedAnswers));

        const updatedStatuses = [...questionStatuses];
        updatedStatuses[currentQuestionIndex] = 'Complete';
        setQuestionStatuses(updatedStatuses);
        sessionStorage.setItem('questionStatuses', JSON.stringify(updatedStatuses));
    };

    const handleQuestionSelect = (index) => {
        setCurrentQuestionIndex(index);
        setIsModalOpen(false);
        updateQuestionStatus(index);
        sessionStorage.setItem('currentQuestionIndex', index);
    };

    const toggleFlag = () => {
        const updatedFlaggedQuestions = [...flaggedQuestions];
        if (updatedFlaggedQuestions.includes(currentQuestionIndex)) {
            const index = updatedFlaggedQuestions.indexOf(currentQuestionIndex);
            updatedFlaggedQuestions.splice(index, 1);
        } else {
            updatedFlaggedQuestions.push(currentQuestionIndex);
        }
        setFlaggedQuestions(updatedFlaggedQuestions);
        sessionStorage.setItem('flaggedQuestions', JSON.stringify(updatedFlaggedQuestions));
    };

    const isFlagged = (index) => flaggedQuestions.includes(index);

    const currentQuestion = questions[currentQuestionIndex];


    useEffect(() => {
        setQuestions(questionInfo);
        preloadImages(questionInfo);
    }, []);

    useEffect(() => {
        if (questions.length > 0) {
            const savedStatuses = sessionStorage.getItem('questionStatuses');
            setQuestionStatuses(
                savedStatuses !== null ? JSON.parse(savedStatuses) : new Array(questions.length).fill('Unseen')
            );
        }
    }, [questions]);


    useEffect(() => {
        if (questions.length > 0) {
            updateQuestionStatus(currentQuestionIndex);
        }
    }, [currentQuestionIndex]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining(prevTime => {
                if (prevTime > 0) {
                    sessionStorage.setItem('timeRemaining', prevTime - 1);
                    return prevTime - 1;
                } else {
                    clearInterval(timer); // Clear interval to prevent repeated navigation calls
                    setTimerEnded(true); // Set timer ended state
                    return 0;
                }
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const handleTimerEnd = async () => {
            if (timerEnded && examType === "timed") {
                setLoading(true);
                await addExamHistory();
                navigate('/examReview', { state: { questions, selectedAnswers } });
                setLoading(false);
            }
        };
    
        handleTimerEnd(); // Call the async function
    
    }, [timerEnded, examType, navigate, questions, selectedAnswers]);

    const handleConfirmSubmit = async () => {

        setLoading(true); 
        await addExamHistory();
        sessionStorage.removeItem('currentQuestionIndex');
        sessionStorage.removeItem('selectedAnswers');
        sessionStorage.removeItem('timeRemaining');
        sessionStorage.removeItem('questions');
        sessionStorage.removeItem('questionStatuses');
        sessionStorage.removeItem('flaggedQuestions');
        setLoading(false);
        navigate('/examReview', { state: { questions, selectedAnswers, topic,  subTopic} });
    };

    const handleSubmit = () => {
        
        setIsConfirmModalOpen(true);
    };

    const closeConfirmModal = () => {
        setIsConfirmModalOpen(false);
    };

    return (
        <div className='exam-page'>
            {loading ? (
                <LoadingIndicator /> // Display loading indicator while loading
            ) : (
                <>
            <ExamHeader 
            examType={examType}
            examName={topic}
            />
            <div className="question-header">
                <div className="left-section">
                {/* {topic === "PAT" && <button className="calculator-toggle" onClick={toggleCalculator}>
                        <i className="fas fa-calculator"></i>
                    </button>} */}
                </div>
                
                <div className="right-section">
                    <span>
                        <button className="flag-button" onClick={toggleFlag}>
                            {isFlagged(currentQuestionIndex) ? 'Unflag' : 'Flag'}
                        </button>
                    </span>
                    <span className="question-index">{currentQuestionIndex + 1} of {questions.length}</span>
                </div>
            </div>
            <div className="exam-container">
                {questions.length > 0 ? (
                    <div className="question-container">
                        <div className="question-body">
                            <p>
                                {currentQuestion.questionData.questionFront && (
                                <Latex>{currentQuestion.questionData.questionFront}</Latex>
                                )}
                            </p>
                            <p>
                                {currentQuestion.questionData.questionBody && (
                                <Latex>{currentQuestion.questionData.questionBody}</Latex>
                                )}
                            </p>
                            {currentQuestion.questionData.questionImage && (
                                <img className="question-image" src={currentQuestion.questionData.questionImage} alt={`Question ${currentQuestionIndex + 1}`} />
                            )}
                            <ul className="selections-list">
                                {currentQuestion.selections.map((selection, index) => (
                                    <li key={index}>
                                        <input
                                            type="radio"
                                            id={`selection${index}`}
                                            name={`selection${currentQuestionIndex}`}
                                            value={selection.selectionText}
                                            checked={selectedAnswers[currentQuestionIndex] === selection.selectionLabel}
                                            onChange={() => handleSelectionChange(currentQuestionIndex, selection.selectionLabel)}
                                        />
                                        {isUrl(selection.selectionText) ? (
                                                // Render image if the selectionText is a URL
                                                <img src={selection.selectionText} alt="Dynamic Image" width="300px" />
                                            ) : (
                                                // Render LaTeX if the selectionText is LaTeX content
                                                <Latex>{selection.selectionText}</Latex>
                                            )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ) : (
                    <p>No questions available</p>
                )}
                <div className="navigation-buttons">
                    <button onClick={handlePrev} disabled={currentQuestionIndex === 0}>
                        Previous
                    </button>
                    {currentQuestionIndex < questions.length - 1 ? (
                        <button onClick={handleNext}>
                            Next
                        </button>
                    ) : (
                        <button onClick={handleSubmit}>
                            Submit
                        </button>
                    )}
                    <button className="navigator-button" onClick={() => setIsModalOpen(true)}>
                        Navigator
                    </button>
                </div>
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    contentLabel="Question Navigation"
                    className="modal"
                    overlayClassName="modal-overlay"
                >
                    <h2>Navigator - select a question to go to it</h2>
                    <table className="modal-table">
                        <thead>
                            <tr>
                                <th>Question #</th>
                                <th>Status</th>
                                <th>Flagged for Review</th>
                            </tr>
                        </thead>
                        <tbody>
                            {questions.map((question, index) => (
                                <tr key={index} onClick={() => handleQuestionSelect(index)} className={index === currentQuestionIndex ? 'selected' : ''}>
                                    <td>Question {index + 1}</td>
                                    <td>{questionStatuses[index]}</td>
                                    <td>{isFlagged(index) ? 'Flagged' : ''}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button className="close-button" onClick={() => setIsModalOpen(false)}>Close</button>
                </Modal>
                <Modal
                    isOpen={isConfirmModalOpen}
                    onRequestClose={closeConfirmModal}
                    contentLabel="Submit Confirmation"
                    className="modal confirmation-modal"
                    overlayClassName="modal-overlay"
                >
                    <div className="confirmation-modal-content">
                        <h2>Confirm Submission</h2>
                        <p>Are you sure you want to submit the exam?</p>
                        <div className="confirm-buttons">
                            <button onClick={handleConfirmSubmit}>Yes</button>
                            <button onClick={closeConfirmModal}>No</button>
                        </div>
                    </div>
                </Modal>
                {/* {isCalculatorOpen && (
                    <Draggable>
                        <div className="draggable-calculator">
                            <Calculator />
                        </div>
                    </Draggable>
                )} */}
            </div>
            </>
            )}
        </div>
    );
}

export default ExamPage;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './pages/HomePage';
import PracticeAndTest from './pages/PracticeAndTest';
import ExamPage from './pages/ExamPage';
import ReviewPage from './pages/ReviewPage';
import ExamReview from './pages/ExamReview';
import TopicPage from './pages/TopicPage';
import LoginPage from './pages/LoginPage'
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import ESATExam from './pages/EsatExam';
import PATExam from './pages/PatExam';
import ServiceInfoPage from './pages/ServiceInfoPage';
import PaymentStatusPage from './pages/PaymentStatus';
import Calculator from './components/Calculator';
import PaymentPage from './pages/PaymentPage';
import SpecialLoginPage from './pages/SpecialLoginPage';

function App() {

  const location = useLocation();

  const renderHeader = () => {
    if (location.pathname !== '/exam' && location.pathname !== '/examReview') {
      return <Header />;
    }
  };

  return (
    <AuthProvider>
      <div className="App">
        {renderHeader()}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register/:token" element={<SpecialLoginPage />} />
          <Route path="/practiceAndTest" element={<PracticeAndTest />} />
          <Route path="/EsatExam" element={<ESATExam />} />
          <Route path="/PatExam" element={<PATExam />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/exam" element={<ExamPage />} />
            <Route path="/review" element={<ReviewPage />} />
            <Route path="/examReview" element={<ExamReview />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/topic/:topic" element={<TopicPage />} />
            <Route path="/exam/:topic/:subTopic" element={<ExamPage />} />
            <Route path="/service-info" element={<ServiceInfoPage />} />
            <Route path="/paymentStatus" element={<PaymentStatusPage/>} />
            <Route path="/paymentPage" element={<PaymentPage/>} />
          </Route>
        </Routes>
      </div>
    </AuthProvider>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
